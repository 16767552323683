// Menu layout modes
export const layoutModeMap = {
    0: 'single',
    1: 'double',
    2: 'zoomed',
    single: 0,
    double: 1,
    zoomed: 2
};

// Filter types
export const filterTypeMap = {
    0: 'none',
    1: 'brand',
    2: 'category',
    none: 0,
    brand: 1,
    category: 2
};

// Sync modes for double layout
export const syncModeMap = {
    0: 'sync',
    1: 'offset',
    2: 'speed',
    sync: 0,
    offset: 1,
    speed: 2
}; 