import React, { useState, useEffect } from 'react';

const StoreReportPage = () => {
  const [stores, setStores] = useState([]);
  
  useEffect(() => {
    const fetchStores = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store-report`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.ok) {
        const data = await response.json();
        setStores(data.stores);
      } else {
        console.error('Failed to fetch stores');
      }
    };
    
    fetchStores();
  }, []);

  const toggleStoreStatus = async (storeId, currentStatus) => {
    const newStatus = !currentStatus;
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store-report`, {
      method: 'POST',
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: storeId, is_active: newStatus }),
    });

    if (response.ok) {
      setStores(prevStores => 
        prevStores.map(store =>
          store.id === storeId ? { ...store, is_active: newStatus } : store
        )
      );
    } else {
      console.error('Failed to update store status');
    }
  };

  return (
    <div>
      <h1>Store Report</h1>
      {stores.map((store, index) => (
        <div 
          key={store.id} 
          style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            marginBottom: '10px', 
            paddingBottom: '10px',
            borderBottom: '1px solid #ccc',
            marginBottom: index === stores.length - 1 ? '0' : '10px', 
          }}
        >
          <span><strong>Name:</strong> {store.name}</span>
          <span><strong>Company:</strong> {store.company}</span>
          <span><strong>Created At:</strong> {new Date(store.created_at).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</span>
          <button 
            onClick={() => toggleStoreStatus(store.id, store.is_active)}
            style={{ backgroundColor: store.is_active ? 'green' : 'red', color: 'white', padding: '5px 10px' }}
          >
            {store.is_active ? 'Active' : 'Inactive'}
          </button>
        </div>
      ))}
    </div>
  );
};

export default StoreReportPage;
