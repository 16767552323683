import React, { useState, useEffect } from 'react';
import './ScrollingMenuSettingsModal.css';
import { layoutModeMap, filterTypeMap, syncModeMap } from '../utils/menuConstants';

const ScrollingMenuSettingsModal = ({ device, isOpen, onClose, onSave }) => {
    const [settings, setSettings] = useState({
        layoutMode: 0,
        filterType: 0,
        filterValue: 'all',
        syncMode: 0
    });
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Get unique brands and categories from products
    const getBrands = () => {
        const brands = [...new Set(products.map(p => p.brandName))].filter(Boolean);
        return brands.sort();
    };

    const getCategories = () => {
        const categories = [...new Set(products.map(p => p.masterCategory || p.category))].filter(Boolean);
        return categories.sort();
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const deviceCode = localStorage.getItem('device_code');
                const url = `${process.env.REACT_APP_API_BASE_URL}/users/get-all-products/`;
                
                const headers = deviceCode 
                    ? { 'Device-Code': deviceCode }
                    : {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': localStorage.getItem('Selected-Store-Id')
                    };

                const response = await fetch(url, { headers });
                if (!response.ok) throw new Error('Failed to fetch products');
                
                const data = await response.json();
                const processedProducts = data.products
                    .filter(product => 
                        product.productName && 
                        product.imageUrls && 
                        product.isActive && 
                        !product.isCoupon &&
                        product.price > 0
                    );
                setProducts(processedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        if (device?.settings) {
            setSettings(device.settings);
        }
    }, [device]);

    if (!isOpen) return null;

    const handleSave = () => {
        onSave(settings);
        onClose();
    };

    return (
        <div className="scrolling-sync-settings-overlay">
            <div className="scrolling-sync-settings-modal">
                <h2 className="scrolling-sync-settings-title">Menu Settings</h2>
                
                <div className="scrolling-sync-settings-section">
                    <h3>Layout</h3>
                    <div className="scrolling-sync-settings-layout-buttons">
                        <button 
                            className={`scrolling-sync-settings-button ${settings.layoutMode === 0 ? 'active' : ''}`}
                            onClick={() => setSettings({...settings, layoutMode: 0})}
                        >
                            Single
                        </button>
                        <button 
                            className={`scrolling-sync-settings-button ${settings.layoutMode === 1 ? 'active' : ''}`}
                            onClick={() => setSettings({...settings, layoutMode: 1})}
                        >
                            Double
                        </button>
                        <button 
                            className={`scrolling-sync-settings-button ${settings.layoutMode === 2 ? 'active' : ''}`}
                            onClick={() => setSettings({...settings, layoutMode: 2})}
                        >
                            Zoomed
                        </button>
                    </div>
                </div>

                <div className="scrolling-sync-settings-section">
                    <h3>Filter</h3>
                    <div className="scrolling-sync-settings-filter-controls">
                        <select 
                            className="scrolling-sync-settings-select"
                            value={settings.filterType}
                            onChange={(e) => setSettings({
                                ...settings, 
                                filterType: parseInt(e.target.value),
                                filterValue: 'all'
                            })}
                        >
                            <option value={0}>No Filter</option>
                            <option value={1}>Filter by Brand</option>
                            <option value={2}>Filter by Category</option>
                        </select>

                        {settings.filterType > 0 && (
                            <select
                                className="scrolling-sync-settings-select"
                                value={settings.filterValue || 'all'}
                                onChange={(e) => setSettings({
                                    ...settings,
                                    filterValue: e.target.value
                                })}
                            >
                                <option value="all">All {settings.filterType === 1 ? 'Brands' : 'Categories'}</option>
                                {settings.filterType === 1 
                                    ? getBrands().map(brand => (
                                        <option key={brand} value={brand}>{brand}</option>
                                    ))
                                    : getCategories().map(category => (
                                        <option key={category} value={category}>{category}</option>
                                    ))
                                }
                            </select>
                        )}
                    </div>
                </div>

                {settings.layoutMode === 1 && (
                    <div className="scrolling-sync-settings-section">
                        <h3>Sync Mode</h3>
                        <div className="scrolling-sync-settings-sync-buttons">
                            <button 
                                className={`scrolling-sync-settings-button ${settings.syncMode === 0 ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, syncMode: 0})}
                            >
                                Sync
                            </button>
                            <button 
                                className={`scrolling-sync-settings-button ${settings.syncMode === 1 ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, syncMode: 1})}
                            >
                                Offset
                            </button>
                            <button 
                                className={`scrolling-sync-settings-button ${settings.syncMode === 2 ? 'active' : ''}`}
                                onClick={() => setSettings({...settings, syncMode: 2})}
                            >
                                Speed
                            </button>
                        </div>
                    </div>
                )}

                <div className="scrolling-sync-settings-modal-buttons">
                    <button className="scrolling-sync-settings-cancel" onClick={onClose}>Cancel</button>
                    <button className="scrolling-sync-settings-save" onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default ScrollingMenuSettingsModal; 