import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Queue from './components/Queue';
import StoresPage from './components/StoresPage';
import Slideshows from './components/SlideshowsPage';
import SlideshowPlayer from './components/SlideshowPlayer';
import DealSheetCreator from './components/DealSheetCreator';
import DealSheetVisualization from './components/DealSheetVisualization';
import DealSheetVisualizationForPDF from './components/DealSheetVisualizationForPDF';
import CustomerFacingMenu from './components/CustomerFacingMenu';
import QRCodeManager from './components/QRCodeManager';
import ProfileManager from './components/ProfileManager';
import EditUserPage from './components/EditUserPage';
import PricingPage from './components/PricingPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HomePage from './components/Home';
import WaitList from './components/WaitList';
import ReorderReport from './components/ReorderReport';
import HealthReport from './components/HealthReport';
import QueueSettingsPage from './components/QueueSettingsPage';
import TnCPage from './components/TnCPage';
import WrappedStoreSubscriptionManager from './components/StoreSubscriptionManager';
import ResetPassword from './components/ResetPassword';
import StoreSelectionPage from './components/StoreSelectionPage';
import SlideshowSelectionPage from './components/SlideshowSelectionPage';
import FunctionSelectPage from './components/FunctionSelectPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeviceSetupPage from './components/DeviceSetupPage';
import DeviceSelectionPage from './components/DeviceSelectionPage';
import EditDevicePage from './components/EditDevicePage';
import StoreReportPage from './components/StoreReportPage';
import EditAppDevicePage from './components/EditDeviceAppPage';
import RedirectToShopify from './components/RedirectToShopify'; // Import the new component
import ScrollingMenu from './components/ScrollingMenu';


const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/queue" element={<Queue />} />
          <Route path="/storespage" element={<StoresPage />} />
          <Route path="/slideshows" element={<Slideshows />} />
          <Route path="/slideshowplayer" element={<SlideshowPlayer />} />
          <Route path="/tools" element={<DealSheetCreator />} />
          <Route path="/dealsheetvisualization" element={<DealSheetVisualization />} />
          <Route path="/temporary_pdf/:linkId" element={<DealSheetVisualizationForPDF />} />
          <Route path="/temporary_pdf_visualization/:link_id" element={<DealSheetVisualizationForPDF />} />
          <Route path="/cfd" element={<CustomerFacingMenu />} />
          <Route path="/QRCodeManager" element={<QRCodeManager />} />
          <Route path="/profile-manager" element={<ProfileManager />} />
          <Route path="/edit-user" element={<EditUserPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/waitlist" element={<WaitList />} />
          <Route path="/reorder-report" element={<ReorderReport />} />
          <Route path="/HealthReport" element={<HealthReport />} />
          <Route path="/QueueSettingsPage" element={<QueueSettingsPage />} />
          <Route path="/tnc" element={<TnCPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/StoreSubscriptions" element={<WrappedStoreSubscriptionManager />} />
          <Route path="/Reset-Password" element={<ResetPassword />} />
          <Route path="/select-store" element={<StoreSelectionPage />} />
          <Route path="/select-slideshow" element={<SlideshowSelectionPage />} />
          <Route path="/setup-device" element={<DeviceSetupPage />} />
          <Route path="/select-device" element={<DeviceSelectionPage />} />
          <Route path="/edit-devices" element={<EditDevicePage />} />
          <Route path="/edit-devices2" element={<EditAppDevicePage />} />
          <Route path="/function-select" element={<FunctionSelectPage />} />
          <Route path="/storereport" element={<StoreReportPage />} />
          <Route path="/hardware" element={<RedirectToShopify />} />
          <Route path="/ScrollingMenu" element={<ScrollingMenu />} />

          <Route path="/download-apk" element={() => {
            window.location.href = '/api/users/download-apk';
            return null;
          }} />
        
          {/* Other routes */}
        </Routes>
      </Router>
    </DndProvider>
  );
};

export default App;
