import React, { useState, useEffect } from 'react';
import { layoutModeMap, filterTypeMap, syncModeMap } from '../utils/menuConstants';
import './ScrollingMenu.css';

const getFilterValue = (filterType, brands, categories, savedValue) => {
    if (!savedValue || savedValue === 'all') return 'all';
    
    // Verify the saved value exists in current data
    if (filterType === 'brand' && brands.includes(savedValue)) {
        return savedValue;
    }
    if (filterType === 'category' && categories.includes(savedValue)) {
        return savedValue;
    }
    
    return 'all'; // Default to all if saved value is invalid
};

const ScrollingMenu = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [layout, setLayout] = useState('single');
    const [filter, setFilter] = useState('all');
    const [filterType, setFilterType] = useState('none'); // 'none', 'brand', or 'category'
    const PRODUCTS_TO_SHOW = 8;
    const [backgroundURL, setBackgroundURL] = useState('');
    const [showHeader, setShowHeader] = useState(true);
    let headerTimeout = null;
    const [syncMode, setSyncMode] = useState('sync'); // 'sync', 'offset', 'speed'

    // Get unique brands and categories from products
    const getBrands = () => {
        const brands = [...new Set(products.map(p => p.brandName))].filter(Boolean);
        return brands.sort();
    };

    const getCategories = () => {
        const categories = [...new Set(products.map(p => p.masterCategory || p.category))].filter(Boolean);
        return categories.sort();
    };

    // Filter products based on selected filter
    const getFilteredProducts = () => {
        if (filter === 'all' || filterType === 'none') return products;
        
        return products.filter(product => {
            if (filterType === 'brand') return product.brandName === filter;
            if (filterType === 'category') return (product.masterCategory || product.category) === filter;
            return true;
        });
    };

    // Update the header to include filter controls
    const renderFilterControls = () => (
        <div className="scrolling-menu-filter-controls">
            <select 
                value={filterType} 
                onChange={(e) => {
                    setFilterType(e.target.value);
                    setFilter('all');
                }}
                className="filter-select"
            >
                <option value="none">No Filter</option>
                <option value="brand">Filter by Brand</option>
                <option value="category">Filter by Category</option>
            </select>

            {filterType !== 'none' && (
                <select 
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    className="filter-select"
                >
                    <option value="all">All {filterType === 'brand' ? 'Brands' : 'Categories'}</option>
                    {filterType === 'brand' 
                        ? getBrands().map(brand => (
                            <option key={brand} value={brand}>{brand}</option>
                        ))
                        : getCategories().map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))
                    }
                </select>
            )}
        </div>
    );

    const renderSyncControls = () => {
        if (layout !== 'double') return null;
        
        return (
            <div className="scrolling-menu-sync-controls">
                <button 
                    className={`sync-button ${syncMode === 'sync' ? 'active' : ''}`}
                    onClick={() => setSyncMode('sync')}
                >
                    Sync
                </button>
                <button 
                    className={`sync-button ${syncMode === 'offset' ? 'active' : ''}`}
                    onClick={() => setSyncMode('offset')}
                >
                    Offset
                </button>
                <button 
                    className={`sync-button ${syncMode === 'speed' ? 'active' : ''}`}
                    onClick={() => setSyncMode('speed')}
                >
                    Speed
                </button>
            </div>
        );
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const deviceCode = localStorage.getItem('device_code');
                const url = `${process.env.REACT_APP_API_BASE_URL}/users/get-all-products/`;

                const headers = deviceCode 
                    ? { 'Device-Code': deviceCode }
                    : {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': localStorage.getItem('Selected-Store-Id')
                    };

                const response = await fetch(url, {
                    headers: headers
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }

                const data = await response.json();
                
                // Set background URL from the response
                if (data.queue_bg_url) {
                    setBackgroundURL(data.queue_bg_url);
                }
                
                // Process products as before
                const processedProducts = data.products  // Note: Changed from data to data.products
                    .filter(product => 
                        product.productName && 
                        product.imageUrls && 
                        product.isActive && 
                        !product.isCoupon &&
                        product.price > 0
                    )
                    .map(product => ({
                        sku: product.sku,
                        productName: product.productName,
                        imageUrls: product.imageUrls,
                        brandName: product.brandName,
                        masterCategory: product.masterCategory,
                        category: product.category,
                        strain: product.strain,
                        price: product.price,
                        isActive: product.isActive
                    }));

                setProducts(processedProducts);
            } catch (err) {
                console.error('Error fetching products:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();

        const handleAnimationEnd = () => {
            const tickers = document.querySelectorAll('.scrolling-menu-ticker');
            tickers.forEach(ticker => {
                ticker.style.animation = 'none';
                void ticker.offsetHeight;
                ticker.style.animation = 'scroll 120s linear infinite';
            });
        };

        const tickers = document.querySelectorAll('.scrolling-menu-ticker');
        tickers.forEach(ticker => {
            ticker.addEventListener('animationend', handleAnimationEnd);
        });

        return () => {
            const tickers = document.querySelectorAll('.scrolling-menu-ticker');
            tickers.forEach(ticker => {
                ticker.removeEventListener('animationend', handleAnimationEnd);
            });
        };
    }, []);

    const handleMouseMove = () => {
        setShowHeader(true);
        clearTimeout(headerTimeout);
        headerTimeout = setTimeout(() => {
            setShowHeader(false);
        }, 10000); // 10 seconds
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(headerTimeout);
        };
    }, []);

    const renderProductCard = (product, index) => {
        // Process the product name - only remove the part before first |
        const displayName = product.productName.includes('|') 
            ? product.productName.split('|').slice(1).join('|').trim()
            : product.productName;

        return (
            <div key={`${product.sku}-${index}`} className={`scrolling-menu-product-card ${layout}`}>
                <div className="scrolling-menu-product-image">
                    <img 
                        src={product.imageUrls ? product.imageUrls[0] : ''} 
                        alt={product.productName}
                        loading="lazy"
                    />
                </div>
                <div className="scrolling-menu-product-info">
                    <div className="scrolling-menu-product-header">
                        <h3 className="scrolling-menu-product-name">{displayName}</h3>
                    </div>
                    <div className="scrolling-menu-product-details">
                        <div className="scrolling-menu-product-metadata">
                            <span className="scrolling-menu-product-brand">{product.brandName}</span>
                            <span className="scrolling-menu-product-category">
                                {product.masterCategory || product.category}
                            </span>
                            {product.strain && product.strain.length <= 6 && (
                                <span className="scrolling-menu-product-strain">
                                    {product.strain}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="scrolling-menu-product-price-section">
                    <div className="scrolling-menu-product-price-container">
                        <span className="scrolling-menu-product-price-label">Price</span>
                        <span className="scrolling-menu-product-price">${product.price}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = (filteredProducts) => {
        // Get products for different sections of the display
        const getDisplayProducts = (section) => {
            const totalProducts = filteredProducts.length;
            const displayCount = PRODUCTS_TO_SHOW;
            const bufferCount = 4;  // Number of additional buffer items
            const minimumRequired = displayCount + bufferCount;
            
            // If we don't have enough products, repeat them until we do
            let workingProducts = filteredProducts;
            while (workingProducts.length < minimumRequired * 2) { // * 2 for both columns
                workingProducts = [...workingProducts, ...filteredProducts];
            }
            
            let startIndex = 0;
            if (section === 'right') {
                // Start halfway through the working set for right column
                startIndex = Math.floor(workingProducts.length / 2);
            }
            
            // Get a slice of products with buffer
            const productsToShow = workingProducts.slice(startIndex, startIndex + displayCount + bufferCount);
            return [...productsToShow, ...productsToShow]; // Duplicate for continuous scroll
        };

        if (layout === 'single') {
            return (
                <div className="scrolling-menu-ticker-wrapper">
                    <div className="scrolling-menu-ticker">
                        {getDisplayProducts('left').map((product, index) => renderProductCard(product, index))}
                    </div>
                </div>
            );
        } else if (layout === 'double') {
            return (
                <div className="scrolling-menu-double-wrapper">
                    <div className="scrolling-menu-ticker-wrapper left">
                        <div className={`scrolling-menu-ticker ${syncMode}`}>
                            {getDisplayProducts('left').map((product, index) => renderProductCard(product, index))}
                        </div>
                    </div>
                    <div className="scrolling-menu-ticker-wrapper right">
                        <div className={`scrolling-menu-ticker ${syncMode} right`}>
                            {getDisplayProducts('right').map((product, index) => renderProductCard(product, index))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="scrolling-menu-ticker-wrapper">
                    <div className="scrolling-menu-ticker">
                        {getDisplayProducts('left').map((product, index) => renderProductCard(product, index))}
                    </div>
                </div>
            );
        }
    };

    useEffect(() => {
        const menuSettings = JSON.parse(localStorage.getItem('menuSettings'));
        if (menuSettings) {
            setLayout(layoutModeMap[menuSettings.layoutMode]);
            
            // Set filter type first
            const newFilterType = filterTypeMap[menuSettings.filterType];
            setFilterType(newFilterType);
            
            // Then set filter value if it exists and matches available options
            if (menuSettings.filterValue) {
                const brands = getBrands();
                const categories = getCategories();
                const validFilterValue = getFilterValue(
                    newFilterType,
                    brands,
                    categories,
                    menuSettings.filterValue
                );
                setFilter(validFilterValue);
            }
            
            if (menuSettings.syncMode !== undefined) {
                setSyncMode(syncModeMap[menuSettings.syncMode]);
            }
        }
    }, [products]); // Add products as dependency to ensure brands/categories are available

    if (loading) return <div className="scrolling-menu-loading">Loading...</div>;
    if (error) return <div className="scrolling-menu-error">Error: {error}</div>;

    return (
        <div 
            className="scrolling-menu-container" 
            style={{ 
                backgroundImage: `url(${backgroundURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className={`scrolling-menu-header ${showHeader ? 'visible' : 'hidden'}`}>
                <h1 className="scrolling-menu-title">Product Menu</h1>
                <div className="scrolling-menu-controls-wrapper">
                    {renderFilterControls()}
                    <div className="scrolling-menu-controls">
                        <button 
                            className={`layout-button ${layout === 'single' ? 'active' : ''}`}
                            onClick={() => setLayout('single')}
                        >
                            Single
                        </button>
                        <button 
                            className={`layout-button ${layout === 'double' ? 'active' : ''}`}
                            onClick={() => setLayout('double')}
                        >
                            Double
                        </button>
                        <button 
                            className={`layout-button ${layout === 'zoomed' ? 'active' : ''}`}
                            onClick={() => setLayout('zoomed')}
                        >
                            Zoomed
                        </button>
                    </div>
                    {renderSyncControls()}
                </div>
            </div>
            {renderContent(getFilteredProducts())}
        </div>
    );
};

export default ScrollingMenu; 